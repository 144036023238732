<template>
    <div class="form">
        <h1>Заявление о признании физического лица квалифицированным инвестором</h1>
        <div class="form__body">
            <client-block :mname="user.userName.mname" :name="user.userName.name" :sname="user.userName.sname"/>
            <passport-block :department="user.passport.department" :issue="user.passport.issue"
                            :issue-date="user.passport.issueDate"
                            :number="user.passport.number"/>
            <address-block
                    :actual-address="user.actualAddress"
                    :birthday="user.birthday"
                    :birthplace="user.birthplace"
                    :email="user.email"
                    :inn="user.inn"
                    :phone="user.phone"
                    :registration-address="user.registrationAddress"
                    :snils="null"
                    :is-nationality="false"
            />
            <div class="block">
                <div class="row">
                    <div class="col-12">
                        <p class="anketa">
                            Настоящим прошу ЗАО УК «РВМ Капитал» признать меня квалифицированным инвестором в отношении
                            следующих видов ценных бумаг,
                            и (или) производных финансовых инструментов, и (или) видов услуг <i>(Перечень видов услуг и
                            перечень видов ценных бумаг и (или) производных инструментов,
                            в отношении которых лицо обращается с просьбой быть признанным квалифицированным
                            инвестором)</i>:
                        </p>
                        <rwm-text-field
                                v-model="_reason"
                                :error="errors.reason"
                                @blur="validate('reason', ...arguments)"
                                style="margin-top: 18px"
                                textarea
                                disabled
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="anketa">
                            На дату настоящего заявления я соответствую следующим требованиям (необходимо отметить не
                            менее одного)
                            в подтверждение чего предоставляю соответствующие документы.
                        </p>
                    </div>
                    <div class="col-12">
                        <rwm-checkbox
                                :error="errors.box.status"
                                :disabled="timer"
                                v-model="_own_securities_6_million_rubles"
                                label="Владею ценными бумагами и (или) общий размер обязательств из договоров, являющихся производными финансовыми инструментами, общая стоимость которых составляет не менее 6 миллионов рублей"
                        />
                    </div>
                    <div class="col-12">
                        <rwm-checkbox
                                :disabled="timer"
                                v-model="_exp"
                                label="Имею опыт работы:"
                                @change="expChecker"
                                :error="errors.box.status"
                        />
                    </div>
                    <transition name="slide" mode="out-in">
                        <div v-if="_exp" class="row level">
                            <div class="col-12">
                                <rwm-checkbox
                                        :disabled="timer"
                                        v-model="_have_experience"
                                        label="в российской и (или) иностранной организации, которая совершала сделки с ценными бумагами и (или) заключала договоры, являющиеся производными финансовыми инструментами:"
                                        @change="setTrue()"
                                        :error="errors.box.status"
                                />
                            </div>
                            <transition name="slide" mode="out-in">
                                <div v-if="_have_experience" class="row level" style="padding-left: 60px">
                                    <div class="col-12">
                                        <rwm-radiobox
                                                :value="_have_experience_least_two_years"
                                                label="не менее 2 (Двух) лет, если такая организация (организации) является квалифицированным инвестором в силу закона;"
                                                name="name1"
                                                :checked="_have_experience_least_two_years"
                                                @change="onGroupSelecting('_have_experience_least_two_years', '_have_experience_least_three_years')"
                                                :disabled="timer"
                                        />
                                        <rwm-radiobox
                                                :value="_have_experience_least_three_years"
                                                label="не менее 3 (Трех) лет в иных случаях;"
                                                name="name1"
                                                :checked="_have_experience_least_three_years"
                                                @change="onGroupSelecting('_have_experience_least_three_years', '_have_experience_least_two_years')"
                                                :disabled="timer"
                                        />
                                    </div>
                                </div>
                            </transition>
                            <div class="col-12">
                                <rwm-checkbox
                                        :disabled="timer"
                                        v-model="_have_cb_rf"
                                        label="в должности, при назначении (избрании) на которую в соответствии с федеральными законами требовалось согласование Банка России."
                                        :error="errors.box.status"
                                />
                            </div>
                        </div>
                    </transition>
                    <div class="col-12">
                        <rwm-checkbox
                                v-model="_made_securities_transactions"
                                label="Совершал(-а) сделки с ценными бумагами и (или) заключал(-а) договоры, являющиеся производными финансовыми инструментами, за последние четыре квартала в среднем не реже 10 раз в квартал, но не реже одного раза в месяц.
При этом совокупная цена таких сделок (договоров) составляла не менее 6 миллионов рублей;"
                                :disabled="timer"
                                :error="errors.box.status"
                        />
                    </div>
                    <div class="col-12">
                        <rwm-checkbox
                                v-model="_own_property"
                                label="Владею имуществом, составляющим не менее 6 миллионов рублей. При этом учитывается только следующее имущество:"
                                :disabled="timer"
                                :error="errors.box.status"
                        />
                    </div>

                    <div class="col-12">
                        <ul class="anketa list">
                            <li>
                                Денежные средства, находящиеся на счетах и (или) во вкладах (депозитах), открытых в
                                кредитных организациях в соответствии с нормативными актами Банка России, и (или) в
                                иностранных банках, с местом учреждения в государствах, указанных в подпунктах 1 и 2
                                пункта 2 статьи 51.1 Федерального закона «О рынке ценных бумаг», и суммы начисленных
                                процентов;
                            </li>
                            <li>
                                Требования к кредитной организации выплатить денежный эквивалент драгоценного металла по
                                учетной цене соответствующего драгоценного металла;
                            </li>
                            <li>
                                Ценные бумаги, в том числе переданные в доверительное управление.
                            </li>
                        </ul>
                    </div>

                    <div class="col-12">
                        <rwm-checkbox
                                v-model="_higher_economic_education"
                                label="Имею высшее экономическое образование, подтвержденное документом государственного образца Российской Федерации о высшем образовании, выданным образовательной организацией высшего профессионального образования, которое на момент выдачи указанного документа осуществляло аттестацию граждан в сфере профессиональной деятельности на рынке ценных бумаг, или любой из следующих аттестатов и сертификатов:"
                                :disabled="timer"
                                :error="errors.box.status"
                                @change="checkEducation"
                        />
                    </div>
                    <div class="row level" style="padding-left: 60px">
                        <div class="col-12">
                            <rwm-checkbox
                                    v-model="_higher_economic_education_certificate_qualification"
                                    label="свидетельство о квалификации, выданного в соответствии с частью 4 статьи 4 Федерального закона от 03.07.2016 № 238-ФЗ «О независимой оценке квалификации»;"
                                    @change="setEducation"
                                    :disabled="timer"
                                    :error="errors.education.status"
                            />
                        </div>
                        <div class="col-12">
                            <rwm-checkbox
                                    v-model="_higher_economic_education_CFA"
                                    label="сертификат «Chartered Financial Analyst (CFA)»;"
                                    @change="setEducation"
                                    :disabled="timer"
                                    :error="errors.education.status"
                            />
                        </div>
                        <div class="col-12">
                            <rwm-checkbox
                                    v-model="_higher_economic_education_CIIA"
                                    label="сертификат «Certified International Investment Analyst (CIIA)»"
                                    @change="setEducation"
                                    :disabled="timer"
                                    :error="errors.education.status"
                            />
                        </div>
                        <div class="col-12">
                            <rwm-checkbox
                                    v-model="_higher_economic_education_FRM"
                                    label="сертификат «Financial Risk Manager (FRM)»."
                                    @change="setEducation"
                                    :disabled="timer"
                                    :error="errors.education.status"
                            />
                        </div>
                    </div>
                    <div class="col-12">
                        <p class="anketa">
                            Настоящим подтверждаю, что я осведомлен об ограничениях, установленных действующим
                            законодательством
                            Российской Федерации в отношении ценных бумаг и (или) иных финансовых инструментов,
                            предназначенных для квалифицированных инвесторов, и особенностях оказания услуг
                            квалифицированным
                            инвесторам, а также о том, что физическим лицам, являющимся владельцами ценных бумаг,
                            предназначенных для квалифицированных инвесторов, в соответствии с п. 2 ст. 19 Федерального
                            закона от 05.03.1999 г. № 46-ФЗ «О защите прав и законных интересов инвесторов на рынке
                            ценных
                            бумаг» не осуществляются выплаты компенсаций из федерального компенсационного фонда.
                        </p>
                        <p class="anketa">
                            Обязуюсь незамедлительно уведомлять ЗАО УК «РВМ Капитал» в случае изменения данных,
                            указанных в п. 1 настоящего Заявления.
                        </p>
                        <p class="anketa">
                            В случае признания меня квалифицированным инвестором обязуюсь незамедлительно уведомить
                            ЗАО УК «РВМ Капитал» о несоблюдении мной требований, соответствие которым необходимо для
                            признания лица квалифицированным инвестором.
                        </p>
                        <p class="anketa">
                            Обязуюсь предоставить по запросу ЗАО УК «РВМ Капитал» информацию и документы, подтверждающие
                            соответствие требованиям, соблюдение которых необходимо для признания лица квалифицированным
                            инвестором.
                        </p>
                        <p class="anketa">
                            Полноту и достоверность информации, содержащейся в данном Заявлении и представленных
                            документах, подтверждаю.
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="anketa">
                            Прикрепите документы, подтверждающие соответствие необходимым требованиям, для признания
                            статуса квалифицированного инвестора.
                        </p>
                    </div>
                </div>
                <div class="row" v-if="files.length > 0">
                    <div class="col-12">
                        <div class="row" style="row-gap: 12px; flex-direction: column">
                            <template v-for="file in files">
                                <div class="col-3" :key="file.name">
                                    <rwm-input-file
                                            :filename="file ? file.origin_name : ''"
                                            title="Прикрепить файл"
                                            @deleteFile="onFileDelete(file.id)"
                                            :disabled="timer"
                                    />
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="files.length < 5">
                    <div class="col-3">
                        <rwm-input-file
                                :filename="null"
                                title="Прикрепить файл"
                                refer="uploadCertificate"
                                @FileChange="onFileLoad($event)"
                                :error="errors.files.status"
                                :disabled="timer"
                        />
                    </div>
                </div>
            </div>
            <div class="block">
                <div class="row">
                    <div class="col-12">
                        <p class="anketa">
                            * После подтверждения уведомление будет направлено Вам по E-mail и прикреплено в Ваш личный
                            кабинет
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <rwm-button width="100%" @click="submit" :disabled="!readyToSubscribe">Подписать</rwm-button>
                    </div>
                </div>
                <rwm-sms-checking v-model="_timer" :isShow="showChecking" :time="60" @sendAgain="sendAgain()"
                                  @verified="codeIsVerified"/>
            </div>
        </div>
    </div>
</template>

<script>
    import ClientBlock from "@/components/pages/cabinet/documents/forms/blocks/ClientBlock";
    import PassportBlock from "@/components/pages/cabinet/documents/forms/blocks/PassportBlock";
    import AddressBlock from "@/components/pages/cabinet/documents/forms/blocks/AddressBlock";
    import RwmTextField from "@/components/RWMTextField/RwmTextField";
    import RwmButton from "@/components/RWMButton/RwmButton";
    import RwmSmsChecking from "@/components/RWMSMSChecking/RwmSmsChecking";
    import RwmCheckbox from "@/components/RWMCheckbox/RwmCheckbox";
    import RwmRadiobox from "@/components/RWMRadioBox/RwmRadioBox";
    import RwmInputFile from "../../../../RWMInputFile/RwmInputFile";

    export default {
        name: "Ki",
        components: {
            RwmInputFile,
            RwmRadiobox,
            RwmCheckbox,
            RwmSmsChecking, RwmButton, RwmTextField, AddressBlock, PassportBlock, ClientBlock
        },
        props: {
            user: Object,
            timer: {
                type: Boolean,
                default: false
            },
            showChecking: {
                type: Boolean,
                default: false
            },
            reason: String,
            own_securities_6_million_rubles: Boolean,
            have_experience: Boolean,
            have_experience_least_two_years: Boolean,
            have_experience_least_three_years: Boolean,
            have_cb_rf: Boolean,
            made_securities_transactions: Boolean,
            own_property: Boolean,
            higher_economic_education: Boolean,
            higher_economic_education_certificate_qualification: Boolean,
            higher_economic_education_CFA: Boolean,
            higher_economic_education_CIIA: Boolean,
            higher_economic_education_FRM: Boolean,
            files: {
                type: Array,
                default: () => []
            }
        },
        computed: {
            _timer: {
                get() {
                    return this.timer
                },
                set(val) {
                    this.$emit('timer', val)
                }
            },
            _reason: {
                get() {
                    return this.reason ? this.reason : this.Reason
                },
                set(val) {
                    this.Reason = val
                    this.$emit('update', {types_of_services: val})
                }
            },
            _own_securities_6_million_rubles: {
                get() {
                    return this.own_securities_6_million_rubles ? this.own_securities_6_million_rubles : this.Own_securities_6_million_rubles
                },
                set(val) {
                    this.Own_securities_6_million_rubles = val
                    this.$emit('update', {own_securities_6_million_rubles: val})
                }
            },
            _have_experience: {
                get() {
                    return this.have_experience ? this.have_experience : this.Have_experience
                },
                set(val) {
                    this.Have_experience = val
                    this.$emit('update', {have_experience: val})
                }
            },
            _have_experience_least_two_years: {
                get() {
                    return this.have_experience_least_two_years ? this.have_experience_least_two_years : this.Have_experience_least_two_years
                },
                set(val) {
                    this.Have_experience_least_two_years = val
                    this.$emit('update', {have_experience_least_two_years: val})
                }
            },
            _have_experience_least_three_years: {
                get() {
                    return this.have_experience_least_three_years ? this.have_experience_least_three_years : this.Have_experience_least_three_years
                },
                set(val) {
                    this.Have_experience_least_three_years = val
                    this.$emit('update', {have_experience_least_three_years: val})
                }
            },
            _have_cb_rf: {
                get() {
                    return this.have_cb_rf ? this.have_cb_rf : this.Have_cb_rf
                },
                set(val) {
                    this.Have_cb_rf = val
                    this.$emit('update', {have_cb_rf: val})
                }
            },
            _made_securities_transactions: {
                get() {
                    return this.made_securities_transactions ? this.made_securities_transactions : this.Made_securities_transactions
                },
                set(val) {
                    this.Made_securities_transactions = val
                    this.$emit('update', {made_securities_transactions: val})
                }
            },
            _own_property: {
                get() {
                    return this.own_property ? this.own_property : this.Own_property
                },
                set(val) {
                    this.Own_property = val
                    this.$emit('update', {own_property: val})
                }
            },
            _higher_economic_education: {
                get() {
                    return this.higher_economic_education ? this.higher_economic_education : this.Higher_economic_education
                },
                set(val) {
                    this.Higher_economic_education = val
                    this.$emit('update', {higher_economic_education: val})
                }
            },
            _higher_economic_education_certificate_qualification: {
                get() {
                    return this.higher_economic_education_certificate_qualification ? this.higher_economic_education_certificate_qualification : this.Higher_economic_education_certificate_qualification
                },
                set(val) {
                    this.Higher_economic_education_certificate_qualification = val
                    this.$emit('update', {higher_economic_education_certificate_qualification: val})
                }
            },
            _higher_economic_education_CFA: {
                get() {
                    return this.higher_economic_education_CFA ? this.higher_economic_education_CFA : this.Higher_economic_education_CFA
                },
                set(val) {
                    this.Higher_economic_education_CFA = val
                    this.$emit('update', {higher_economic_education_CFA: val})
                }
            },
            _higher_economic_education_CIIA: {
                get() {
                    return this.higher_economic_education_CIIA ? this.higher_economic_education_CIIA : this.Higher_economic_education_CIIA
                },
                set(val) {
                    this.Higher_economic_education_CIIA = val
                    this.$emit('update', {higher_economic_education_CIIA: val})
                }
            },
            _higher_economic_education_FRM: {
                get() {
                    return this.higher_economic_education_FRM ? this.higher_economic_education_FRM : this.Higher_economic_education_FRM
                },
                set(val) {
                    this.Higher_economic_education_FRM = val
                    this.$emit('update', {higher_economic_education_FRM: val})
                }
            },
            _exp: {
                get() {
                    return this.exp === null ? this._have_experience : this.exp
                },
                set(val) {
                    this.exp = val
                }
            }
        },
        data() {
            return {
                exp: null,
                readyToSubscribe: true,
                Reason: null,
                Own_securities_6_million_rubles: null,
                Have_experience: null,
                Have_experience_least_two_years: null,
                Have_experience_least_three_years: null,
                Have_cb_rf: null,
                Made_securities_transactions: null,
                Own_property: null,
                Higher_economic_education: null,
                Higher_economic_education_certificate_qualification: null,
                Higher_economic_education_CFA: null,
                Higher_economic_education_CIIA: null,
                Higher_economic_education_FRM: null,
                errors: {
                    files: {
                        status: false,
                        description: "Необходимо загрузить файл"
                    },
                    education: {
                        status: false,
                        description: "Выберите соответствующий сертификат"
                    },
                    box: {
                        status: false,
                        description: "Необходимо отметить не менее одного пункта"
                    },
                },

            }
        },
        methods: {
            onFileLoad(event) {
                event.preventDefault();
                this.$emit('fileLoad', event.target.files[0])
                this.errors.files.status = false
                this.readyToSubscribe = true
            },
            onFileDelete(type) {
                this.$emit('fileDelete', type)
            },
            expChecker(event) {
                if (!event) {
                    this._have_cb_rf = this._have_experience = false
                    this._have_experience = false
                    this._have_experience_least_three_years = false
                    this._have_experience_least_two_years = false

                }
            },
            checkEducation(event) {
                if (!event) {
                    this._higher_economic_education_certificate_qualification =
                    this._higher_economic_education_CFA =
                    this._higher_economic_education_CIIA =
                    this._higher_economic_education_FRM = false
                }
            },

            setEducation() {
                if (!this._higher_economic_education) {
                    this._higher_economic_education = true
                } else {
                    if (!this.Higher_economic_education_certificate_qualification &&
                        !this.Higher_economic_education_CFA &&
                        !this.Higher_economic_education_CIIA &&
                        !this.Higher_economic_education_FRM
                    )
                        this._higher_economic_education = false
                }
            },
            onGroupSelecting(on, off) {
                this[on] = true
                this[off] = false
            },
            setTrue() {
                if (this.Have_experience) {
                    this._have_experience_least_two_years = true
                } else {
                    this._have_experience_least_two_years = false
                    this._have_experience_least_three_years = false
                }
            },
            isRedyToSubcrybe() {
                this.errors.files.status = this.files.length === 0
                this.errors.box.status = !(this._have_experience ||
                        this._higher_economic_education ||
                        this._made_securities_transactions ||
                        this._own_property ||
                        this._own_securities_6_million_rubles)

                this.errors.education.status = this._higher_economic_education &&
                    (
                        !this._higher_economic_education_certificate_qualification &&
                        !this._higher_economic_education_CFA &&
                        !this._higher_economic_education_CIIA &&
                        !this._higher_economic_education_FRM
                    )
                let result = true
                for (let key in this.errors) {
                    if (this.errors[key].status) {
                        result = false
                        this.$toast.error(this.errors[key].description)
                        break
                    }
                }
                return result
            },
            sendAgain() {
                this.$emit('sendAgain')
            },
            submit() {
                if (this.readyToSubscribe && this.isRedyToSubcrybe()) {
                    this.$emit('submit')
                }
            },
            codeIsVerified(code) {
                this.$emit('verified', code)
            },
        }
    }
</script>
<style scoped lang="scss">
    .form {
        text-align: left;

        & h1 {
            margin-bottom: 32px;
        }

        &__body {
            display: flex;
            flex-direction: column;
            row-gap: 32px;

            & .block {
                display: flex;
                flex-direction: column;
                row-gap: 24px;

                &:after {
                    content: "";
                    opacity: 0.5;
                    border: 1px solid $RWM-grey-light;
                }

                &:last-child {
                    &:after {
                        border: none;
                    }
                }

                & .row {
                    row-gap: 24px;

                    & .col-3 {
                        width: 100%;
                    }
                }

                & .anketa {
                    margin-bottom: 20px;
                    flex: 1 1 100%;
                    @media screen and (min-width: 1024px) {
                        flex: 1 1 auto;
                    }
                }
            }

            & .list {
                padding-left: 80px;
                margin: 0 !important;

                & li {
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .level {
        padding-left: 60px;
        width: calc(100% - 60px);
    }
</style>
